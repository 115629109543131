// Exports Petstore.yaml

export default `openapi: 3.0.0
info:
  version: "1"
  title: "Skeleton"
  description: Manifest description
servers:
  - url: http://server-host/openapi/Skeleton/v1
tags:
  - name: Resource
paths:
  "/resource":
    post:
      tags:
        - Resource
      summary: "Make an resource"
      description: ""
      requestBody:
        description: ""
        required: true
        content:
          application/json:
            schema:
              $ref: '#/components/schemas/ResourcePostRequest'
      responses:
        "200":
          description: "Order successfully created"
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/ResourceResult'
        "500":
          description: 'Some internal error'
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/ErrorResult'
    get:
      tags:
        - Resource
      summary: "Get list of resource"
      description: "Get list of resource"
      parameters:
        - in: query
          name: "filter"
          description: "Returning orders with by a specific filter."
          required: false
          schema:
            type: string
      responses:
        "200":
          description: "Success"
          content:
            application/json:
              schema:
                $ref: "#/components/schemas/ResourceListResult"
        '500':
          description: "Internal error"
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/ErrorResult'
  "/resource/{id}":
    get:
      tags:
        - Resource
      summary: 'Get info about specific resource'
      description: "Returns information of resource by id"
      parameters:
        - in: path
          name: id
          description: Id of resource
          required: true
          schema:
            type: string
      responses:
        "200":
          description: 'Success'
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/ResourceResult'
        "500":
          description: 'Some internal error'
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/ErrorResult'
components:
  schemas:
    # Basic results components
    ErrorResult:
      type: object
      properties:
        messages:
          type: array
          items:
            $ref: "#/components/schemas/Message"
      description: "Message field is not required"
    Message:
      type: object
      properties:
        level:
          type: string
          enum:
            - emergency
            - alert
            - critical
            - error
            - warning
            - notice
            - info
        type:
          type: string
          enum:
            - UNDEFINED
            - LOGGER_MESSAGE
          description: >
            You can expose this enum for all your errors
            UNDEFINED - Any undefined message type
            LOGGER_MESSAGE - Same as undefined
        text:
          type: string
          description: Message, that describes what went wrong
    SuccessResult:
      allOf:
        - $ref: '#/components/schemas/ErrorResult'
      type: object
      properties:
        data:
          type: object

    ## One resource result
    ResourceResult:
      allOf:
        - $ref: '#/components/schemas/SuccessResult'
      type: object
      properties:
        data:
          $ref: '#/components/schemas/Resource'

    ## Result with list of resource
    ResourceListResult:
      allOf:
        - $ref: '#/components/schemas/SuccessResult'
      type: object
      properties:
        data:
          type: array
          items:
            $ref: '#/components/schemas/Resource'

    ## Body for post request
    ResourcePostRequest:
      type: object
      required:
        - requiredField
      properties:
        requiredField:
          type: string
          description: required
        optionalField:
          type: string
          nullable: true
          description: optional

    ## Resource object
    Resource:
      type: object
      properties:
        id:
          type: string
          description: Unique identificator of order (generated by server).
          example: "AP7734"
        requiredField:
          type: string
          description: Order number received from supplier
        optionalField:
          type: string
          nullable: true
          example: null
        objectField:
          $ref: '#/components/schemas/NestedObject'
        arrayField:
          type: array
          description: List of ordered products
          items:
            $ref: '#/components/schemas/NestedObject'

    NestedObject:
      type: object
      properties:
        name:
          type: string
          description: Some name
          example: "name"`
